/*
	Hyperspace by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

declare const breakpoints: any;
declare const browser: any;

var loaded = false;

export const pageReady = (jQuery: any) => {
	if (!loaded) {
		loaded = true;

		let $body = jQuery('body');

		// Play initial animations on page load.
		window.setTimeout(function () {
			$body.removeClass('is-preload');
		}, 100);
	}
};

export const initTheme = (jQuery: any) => {
	var $body = jQuery('body');

	// Breakpoints.
	breakpoints({
		xlarge: ['1281px', '1680px'],
		large: ['981px', '1280px'],
		medium: ['737px', '980px'],
		small: ['481px', '736px'],
		xsmall: [null, '480px']
	});

	// Hack: Enable IE flexbox workarounds.
	if (browser.name == 'ie') {
		$body.addClass('is-ie');
	}

	// Forms.

	// Hack: Activate non-input submits.
	jQuery('form').on('click', '.submit', function (event: any) {
		// Stop propagation, default.
		event.stopPropagation();
		event.preventDefault();

		// Submit form.
		jQuery(this).parents('form').submit();
	});

	// Spotlights.
	jQuery('.spotlights > section')
		.scrollex({
			mode: 'middle',
			top: '-10vh',
			bottom: '-10vh',
			initialize: function () {
				// Deactivate section.
				jQuery(this).addClass('inactive');
			},
			enter: function () {
				// Activate section.
				jQuery(this).removeClass('inactive');
			}
		})
		.each(function () {
			var $this = jQuery(this),
				$image = $this.find('.image'),
				$img = $image.find('img'),
				x;

			// Assign image.
			$image.css('background-image', 'url(' + $img.attr('src') + ')');

			// Set background position.
			if ((x = $img.data('position'))) $image.css('background-position', x);

			// Hide <img>.
			$img.hide();
		});

	// Features.
	jQuery('.features').scrollex({
		mode: 'middle',
		top: '-20vh',
		bottom: '-20vh',
		initialize: function () {
			// Deactivate section.
			jQuery(this).addClass('inactive');
		},
		enter: function () {
			// Activate section.
			jQuery(this).removeClass('inactive');
		}
	});
};

export const initNav = (jQuery: any) => {
	let $sidebar = jQuery('#sidebar');

	// Sidebar.
	if ($sidebar.length > 0) {
		var $sidebar_a = $sidebar.find('a');

		$sidebar_a
			.addClass('scrolly')
			.on('click', function () {
				var $this = jQuery(this);

				// External link? Bail.
				if ($this.attr('href').charAt(0) != '#') return;

				// Deactivate all links.
				$sidebar_a.removeClass('active');

				// Activate link *and* lock it (so Scrollex doesn't try to activate other links as we're scrolling to this one's section).
				$this.addClass('active').addClass('active-locked');
			})
			.each(function () {
				var $this = jQuery(this),
					id = $this.attr('href'),
					$section = jQuery(id);

				// No section for this link? Bail.
				if ($section.length < 1) return;

				// Scrollex.
				$section.scrollex({
					mode: 'middle',
					top: '-20vh',
					bottom: '-20vh',
					initialize: function () {
						// Deactivate section.
						$section.addClass('inactive');
					},
					enter: function () {
						// Activate section.
						$section.removeClass('inactive');

						// No locked links? Deactivate all links and activate this section's one.
						if ($sidebar_a.filter('.active-locked').length == 0) {
							$sidebar_a.removeClass('active');
							$this.addClass('active');
						}

						// Otherwise, if this section's link is the one that's locked, unlock it.
						else if ($this.hasClass('active-locked')) $this.removeClass('active-locked');
					}
				});
			});
	}

	// Scrolly.
	jQuery('.scrolly').scrolly({
		speed: 1000,
		offset: function () {
			// If <=large, >small, and sidebar is present, use its height as the offset.
			if (breakpoints.active('<=large') && !breakpoints.active('<=small') && $sidebar.length > 0)
				return $sidebar.height();

			return 0;
		}
	});
};
